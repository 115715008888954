import { default as indexyzbkdtPKO0Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/[category]/index.vue?macro=true";
import { default as indexkedDd14NWhMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/components/index.ts?macro=true";
import { default as PageFiltersf8qxR1FbH9Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/components/PageFilters.vue?macro=true";
import { default as SectionGenreskzsk7NLnXSMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/components/SectionGenres.vue?macro=true";
import { default as SectionMoviesT8CNRn05ptMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/components/SectionMovies.vue?macro=true";
import { default as SectionPopularu4i4S8tR5CMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/components/SectionPopular.vue?macro=true";
import { default as SectionSeriesXEnaZ6Ty0LMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/components/SectionSeries.vue?macro=true";
import { default as SkeletonFilters0GIr2uvPR6Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/components/SkeletonFilters.vue?macro=true";
import { default as SkeletonGenresGFSLKXehw8Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/components/SkeletonGenres.vue?macro=true";
import { default as _91slug_93vKpOO5muJsMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/genre/[slug].vue?macro=true";
import { default as indexGW1J3I6zCeMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/index.vue?macro=true";
import { default as _91slug_93iHhNYXGLeUMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/collections/[slug].vue?macro=true";
import { default as indexch3OTTZ4f0Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/collections/index.vue?macro=true";
import { default as copyrightHOBI0rgbF9Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/copyright.vue?macro=true";
import { default as _91id_93M86t9TCt4mMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/crew/[id].vue?macro=true";
import { default as indexdGpM4RrYZaMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/faq/index.vue?macro=true";
import { default as allJ6rjLYGCdZMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/all.vue?macro=true";
import { default as SectionBoughtxastrHY5NYMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/components/SectionBought.vue?macro=true";
import { default as SectionContinuefW7i7R6o0pMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/components/SectionContinue.vue?macro=true";
import { default as SectionFavoriteszYG3hK4R4OMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/components/SectionFavorites.vue?macro=true";
import { default as SectionViewedJuuTsyalGYMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/components/SectionViewed.vue?macro=true";
import { default as continuevZO4BK1yqCMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/continue.vue?macro=true";
import { default as indexn1zrnscbJkMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/index.vue?macro=true";
import { default as purchasedShnBZwsrrkMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/purchased.vue?macro=true";
import { default as viewedHicpG6XKOAMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/viewed.vue?macro=true";
import { default as holder_45policy9TgjCi5lgxMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/holder-policy.vue?macro=true";
import { default as SectionAdvantageshXRqaagGwOMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionAdvantages.vue?macro=true";
import { default as SectionCollections5dKH850zEnMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionCollections.vue?macro=true";
import { default as SectionContinue3bctB2LRPQMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionContinue.vue?macro=true";
import { default as SectionFilmsf7l0VzyENXMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionFilms.vue?macro=true";
import { default as SectionGenreskgT8jh54h7Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionGenres.vue?macro=true";
import { default as SectionHeaderDq2nloGKRNMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionHeader.vue?macro=true";
import { default as SectionPopularlw8J0cfP72Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionPopular.vue?macro=true";
import { default as SectionPremiersOB44mBnDEYMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionPremiers.vue?macro=true";
import { default as SectionSeriesLRuauBqitaMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionSeries.vue?macro=true";
import { default as SectionSubscriptionsh2XmczOlCMMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionSubscriptions.vue?macro=true";
import { default as SectionSummarize5Tr2rExRgjMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionSummarize.vue?macro=true";
import { default as SectionUpcomingSipGGEpoZmMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionUpcoming.vue?macro=true";
import { default as Index3soUdM4nYrMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/Index.vue?macro=true";
import { default as _91slug_93gcQToFtW4GMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/install/[slug].vue?macro=true";
import { default as InfoInstallcIuucXyaOtMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/install/components/InfoInstall.vue?macro=true";
import { default as TitleInstallDrrIbBXXLNMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/install/components/TitleInstall.vue?macro=true";
import { default as typesEjfHgfBsRrMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/install/components/types.ts?macro=true";
import { default as useInstalltsQNA4ROtvMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/install/components/useInstall.ts?macro=true";
import { default as filmcrewP8ohuyY8UPMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/[slug]/filmcrew.vue?macro=true";
import { default as indexdWzwqGZQy1Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/[slug]/index.vue?macro=true";
import { default as MovieCollectionsCqTgZ5aWIsMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieCollections.vue?macro=true";
import { default as MovieCrewsyQXITA1OXMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieCrew.vue?macro=true";
import { default as MovieDescriptionDfR7Izd7bbMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieDescription.vue?macro=true";
import { default as MovieHeaderaIpQBJkGOTMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieHeader.vue?macro=true";
import { default as MovieHeaderNoticexKz78sctAwMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieHeaderNotice.vue?macro=true";
import { default as MovieHeaderSkeletonPo7ji4oBC8Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieHeaderSkeleton.vue?macro=true";
import { default as MoviePlayButtond44rNro5ScMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MoviePlayButton.vue?macro=true";
import { default as MovieRelatedngkAT2WfvsMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieRelated.vue?macro=true";
import { default as MovieSeriesihAXdE6UmXMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieSeries.vue?macro=true";
import { default as MovieTrailersU5ts7wJtvmMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieTrailers.vue?macro=true";
import { default as MovieWatchOptionsl4QDXLJiAqMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieWatchOptions.vue?macro=true";
import { default as _91slug_93vWZhqzHuwJMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/news/[slug].vue?macro=true";
import { default as indexApWtsMZ0bYMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/news/index.vue?macro=true";
import { default as novelty_45filmsImyXnygKjDMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/novelty-films.vue?macro=true";
import { default as novelty_45seriesBpqExQU6y1Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/novelty-series.vue?macro=true";
import { default as policy_45agreementlC9kd1HF5zMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/policy-agreement.vue?macro=true";
import { default as policy_45copyrightBExxSdARdfMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/policy-copyright.vue?macro=true";
import { default as policy_45holderN2hdkOvuPPMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/policy-holder.vue?macro=true";
import { default as policy_45privatefUeaM1xztnMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/policy-private.vue?macro=true";
import { default as policy_45userSSq6bojyI5Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/policy-user.vue?macro=true";
import { default as premiersi19bcwvVaBMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/premiers.vue?macro=true";
import { default as private_45policy7aYJVHgENAMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/private-policy.vue?macro=true";
import { default as indexEpY2GtxAbSMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/bonuses/index.vue?macro=true";
import { default as VChangeRecurrentCardModal87PC01lk5AMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue?macro=true";
import { default as VEditProfileooVJlwehSkMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/modal/VEditProfile.vue?macro=true";
import { default as VRejectSubscribeModalhE8TAfn9F8Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/modal/VRejectSubscribeModal.vue?macro=true";
import { default as VResumeSubscriptionSuccessModalmL7BmNaANKMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue?macro=true";
import { default as PersonalReferrer3yIrNZtwo1Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/PersonalReferrer.vue?macro=true";
import { default as validationhXUTNIKn1rMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/validation.ts?macro=true";
import { default as VBonusHistoryX9YYqdL9FLMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/VBonusHistory.vue?macro=true";
import { default as VNotificationCardCu2pZCs3zZMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/VNotificationCard.vue?macro=true";
import { default as VNotificationsSettingsXuR6I6B61ZMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/VNotificationsSettings.vue?macro=true";
import { default as VPaymentsVpOuYb0AfOMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/VPayments.vue?macro=true";
import { default as VProfileTabs7sSEHZSQ6wMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/VProfileTabs.vue?macro=true";
import { default as VReferralProgram8yJCAy8aYsMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/VReferralProgram.vue?macro=true";
import { default as VSubscriptions4AidcWIQPXMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/VSubscriptions.vue?macro=true";
import { default as indexzasrtHzKPpMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/devices/index.vue?macro=true";
import { default as index05qdP4bpHRMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/index.vue?macro=true";
import { default as indexSfk7qsUrl9Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/notifications/index.vue?macro=true";
import { default as indexF2iBEblYfHMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/payments/index.vue?macro=true";
import { default as indexlmoZHv6XjBMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/settings/index.vue?macro=true";
import { default as result4FRHVCyNU7Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/result.vue?macro=true";
import { default as _91slug_93k7F1Z05LVRMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/similar/[slug].vue?macro=true";
import { default as indexkM7QAwHnXQMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/sports/channels/index.vue?macro=true";
import { default as indexmPy4ETbNUAMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/sports/index.vue?macro=true";
import { default as SectionAdsU3wUnIL2XGMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/subscriptions/components/SectionAds.vue?macro=true";
import { default as SectionContentGMmaN8YkhfMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/subscriptions/components/SectionContent.vue?macro=true";
import { default as SectionDevices8kFMiT11mIMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/subscriptions/components/SectionDevices.vue?macro=true";
import { default as SectionDownloadcIkAILych3Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/subscriptions/components/SectionDownload.vue?macro=true";
import { default as SectionHeader7MU4GFLG5rMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/subscriptions/components/SectionHeader.vue?macro=true";
import { default as SectionSubscriptionsWRBbkGmHI0Meta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/subscriptions/components/SectionSubscriptions.vue?macro=true";
import { default as SectionVoiceoverSobSWahoRBMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/subscriptions/components/SectionVoiceover.vue?macro=true";
import { default as index2tzlrLlsKqMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/subscriptions/index.vue?macro=true";
import { default as termswgRyPsnVvDMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/terms.vue?macro=true";
import { default as upcomingox6DISLfMHMeta } from "/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/upcoming.vue?macro=true";
export default [
  {
    name: "browse-category",
    path: "/browse/:category()",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "browse-components",
    path: "/browse/components",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/components/index.ts").then(m => m.default || m)
  },
  {
    name: "browse-components-PageFilters",
    path: "/browse/components/PageFilters",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/components/PageFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionGenres",
    path: "/browse/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionMovies",
    path: "/browse/components/SectionMovies",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/components/SectionMovies.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionPopular",
    path: "/browse/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionSeries",
    path: "/browse/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonFilters",
    path: "/browse/components/SkeletonFilters",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/components/SkeletonFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonGenres",
    path: "/browse/components/SkeletonGenres",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/components/SkeletonGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-genre-slug",
    path: "/browse/genre/:slug()",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: "browse",
    path: "/browse",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/browse/index.vue").then(m => m.default || m)
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/collections/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "copyright",
    path: "/copyright",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/copyright.vue").then(m => m.default || m)
  },
  {
    name: "crew-id",
    path: "/crew/:id()",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/crew/[id].vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-all",
    path: "/favorites/all",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/all.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionBought",
    path: "/favorites/components/SectionBought",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/components/SectionBought.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionContinue",
    path: "/favorites/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionFavorites",
    path: "/favorites/components/SectionFavorites",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/components/SectionFavorites.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionViewed",
    path: "/favorites/components/SectionViewed",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/components/SectionViewed.vue").then(m => m.default || m)
  },
  {
    name: "favorites-continue",
    path: "/favorites/continue",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/continue.vue").then(m => m.default || m)
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-purchased",
    path: "/favorites/purchased",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/purchased.vue").then(m => m.default || m)
  },
  {
    name: "favorites-viewed",
    path: "/favorites/viewed",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/favorites/viewed.vue").then(m => m.default || m)
  },
  {
    name: "holder-policy",
    path: "/holder-policy",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/holder-policy.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionAdvantages",
    path: "/home/components/SectionAdvantages",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionAdvantages.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionCollections",
    path: "/home/components/SectionCollections",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionCollections.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionContinue",
    path: "/home/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionFilms",
    path: "/home/components/SectionFilms",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionFilms.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionGenres",
    path: "/home/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionHeader",
    path: "/home/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPopular",
    path: "/home/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPremiers",
    path: "/home/components/SectionPremiers",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionPremiers.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSeries",
    path: "/home/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSubscriptions",
    path: "/home/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSummarize",
    path: "/home/components/SectionSummarize",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionSummarize.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionUpcoming",
    path: "/home/components/SectionUpcoming",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/components/SectionUpcoming.vue").then(m => m.default || m)
  },
  {
    name: "home-Index",
    path: "/home/Index",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/home/Index.vue").then(m => m.default || m)
  },
  {
    name: "install-slug",
    path: "/install/:slug()",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/install/[slug].vue").then(m => m.default || m)
  },
  {
    name: "install-components-InfoInstall",
    path: "/install/components/InfoInstall",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/install/components/InfoInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-TitleInstall",
    path: "/install/components/TitleInstall",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/install/components/TitleInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-types",
    path: "/install/components/types",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/install/components/types.ts").then(m => m.default || m)
  },
  {
    name: "install-components-useInstall",
    path: "/install/components/useInstall",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/install/components/useInstall.ts").then(m => m.default || m)
  },
  {
    name: "movie-slug-filmcrew",
    path: "/movie/:slug()/filmcrew",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/[slug]/filmcrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-slug",
    path: "/movie/:slug()",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCollections",
    path: "/movie/components/MovieCollections",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieCollections.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCrew",
    path: "/movie/components/MovieCrew",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieCrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieDescription",
    path: "/movie/components/MovieDescription",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieDescription.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeader",
    path: "/movie/components/MovieHeader",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieHeader.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderNotice",
    path: "/movie/components/MovieHeaderNotice",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieHeaderNotice.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderSkeleton",
    path: "/movie/components/MovieHeaderSkeleton",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieHeaderSkeleton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MoviePlayButton",
    path: "/movie/components/MoviePlayButton",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MoviePlayButton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieRelated",
    path: "/movie/components/MovieRelated",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieRelated.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieSeries",
    path: "/movie/components/MovieSeries",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieSeries.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieTrailers",
    path: "/movie/components/MovieTrailers",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieTrailers.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieWatchOptions",
    path: "/movie/components/MovieWatchOptions",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/movie/components/MovieWatchOptions.vue").then(m => m.default || m)
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "novelty-films",
    path: "/novelty-films",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/novelty-films.vue").then(m => m.default || m)
  },
  {
    name: "novelty-series",
    path: "/novelty-series",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/novelty-series.vue").then(m => m.default || m)
  },
  {
    name: "policy-agreement",
    path: "/policy-agreement",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/policy-agreement.vue").then(m => m.default || m)
  },
  {
    name: "policy-copyright",
    path: "/policy-copyright",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/policy-copyright.vue").then(m => m.default || m)
  },
  {
    name: "policy-holder",
    path: "/policy-holder",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/policy-holder.vue").then(m => m.default || m)
  },
  {
    name: "policy-private",
    path: "/policy-private",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/policy-private.vue").then(m => m.default || m)
  },
  {
    name: "policy-user",
    path: "/policy-user",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/policy-user.vue").then(m => m.default || m)
  },
  {
    name: "premiers",
    path: "/premiers",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/premiers.vue").then(m => m.default || m)
  },
  {
    name: "private-policy",
    path: "/private-policy",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/private-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile-bonuses",
    path: "/profile/bonuses",
    meta: indexEpY2GtxAbSMeta || {},
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/bonuses/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VChangeRecurrentCardModal",
    path: "/profile/components/modal/VChangeRecurrentCardModal",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VEditProfile",
    path: "/profile/components/modal/VEditProfile",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/modal/VEditProfile.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRejectSubscribeModal",
    path: "/profile/components/modal/VRejectSubscribeModal",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/modal/VRejectSubscribeModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VResumeSubscriptionSuccessModal",
    path: "/profile/components/modal/VResumeSubscriptionSuccessModal",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-PersonalReferrer",
    path: "/profile/components/PersonalReferrer",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/PersonalReferrer.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-validation",
    path: "/profile/components/validation",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/validation.ts").then(m => m.default || m)
  },
  {
    name: "profile-components-VBonusHistory",
    path: "/profile/components/VBonusHistory",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/VBonusHistory.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationCard",
    path: "/profile/components/VNotificationCard",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/VNotificationCard.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationsSettings",
    path: "/profile/components/VNotificationsSettings",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/VNotificationsSettings.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VPayments",
    path: "/profile/components/VPayments",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/VPayments.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VProfileTabs",
    path: "/profile/components/VProfileTabs",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/VProfileTabs.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VReferralProgram",
    path: "/profile/components/VReferralProgram",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/VReferralProgram.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VSubscriptions",
    path: "/profile/components/VSubscriptions",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/components/VSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "profile-devices",
    path: "/profile/devices",
    meta: indexzasrtHzKPpMeta || {},
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/devices/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: index05qdP4bpHRMeta || {},
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: indexSfk7qsUrl9Meta || {},
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-payments",
    path: "/profile/payments",
    meta: indexF2iBEblYfHMeta || {},
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: indexlmoZHv6XjBMeta || {},
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/profile/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "result",
    path: "/result",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/result.vue").then(m => m.default || m)
  },
  {
    name: "similar-slug",
    path: "/similar/:slug()",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/similar/[slug].vue").then(m => m.default || m)
  },
  {
    name: "sports-channels",
    path: "/sports/channels",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/sports/channels/index.vue").then(m => m.default || m)
  },
  {
    name: "sports",
    path: "/sports",
    meta: indexmPy4ETbNUAMeta || {},
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionAds",
    path: "/subscriptions/components/SectionAds",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/subscriptions/components/SectionAds.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionContent",
    path: "/subscriptions/components/SectionContent",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/subscriptions/components/SectionContent.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDevices",
    path: "/subscriptions/components/SectionDevices",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/subscriptions/components/SectionDevices.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDownload",
    path: "/subscriptions/components/SectionDownload",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/subscriptions/components/SectionDownload.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionHeader",
    path: "/subscriptions/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/subscriptions/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionSubscriptions",
    path: "/subscriptions/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/subscriptions/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionVoiceover",
    path: "/subscriptions/components/SectionVoiceover",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/subscriptions/components/SectionVoiceover.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "upcoming",
    path: "/upcoming",
    component: () => import("/srv/gitlab-runner/films/persite_redesign/persite-redesign-306850/src/pages/upcoming.vue").then(m => m.default || m)
  }
]